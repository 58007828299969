import { useSignal } from '@preact/signals';
import { useEffect } from 'preact/hooks';
import { SearchBar } from './search/SearchBar';
import { HP_SEARCH_FIELD_ID } from './search/signals';
import { useRevealOnElementLeftViewport } from './useRevealOnElementLeftViewport';
import { HEADER_DATA_ELEMENT_ID } from 'utils/constants';
import { cn } from 'utils/cn.tsx';

type Props = {
	className?: string;
	showSearch?: boolean;
	showSearchOnScroll: boolean;
	noWrap?: boolean;
	focusedInputClassName?: string;
};

export function HeaderSearchBar({ className, showSearch, showSearchOnScroll = false, focusedInputClassName }: Props) {
	const showSearchSignal = useSignal(showSearch);

	useEffect(() => {
		globalThis.document.addEventListener('astro:after-swap', () => {
			const headerData = globalThis.document.getElementById(HEADER_DATA_ELEMENT_ID);
			if (headerData) {
				showSearchSignal.value = headerData.getAttribute('data-show-search') === 'true';
			}
		});
	}, []);

	if (!showSearchSignal.value) {
		return <div class="contents" />;
	}

	const isVisibleOnScroll = useRevealOnElementLeftViewport(HP_SEARCH_FIELD_ID, !showSearchOnScroll);

	return (
		<SearchBar
			className={cn('mx-1 my-3 md:mx-5 md:my-0 order-2', className, !isVisibleOnScroll.value && 'hidden')}
			focusedInputClassName={focusedInputClassName}
			isFromHeader
		/>
	);
}
