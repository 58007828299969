import LogoSbazar from 'components/logoSbazar/LogoSbazar';
import { cn } from 'utils/cn';
import { HEADER_DATA_ELEMENT_ID, HP_LOGO_ELEMENT_ID } from 'utils/constants';
import { RevealOnElementLeftViewport } from './RevealOnElementLeftViewport';
import { useEffect } from 'preact/hooks';
import { useSignal } from '@preact/signals';

type Props = {
	showLogo?: boolean;
	noWrap?: boolean;
	isScrolledAwayOfHeaderHeight?: boolean;
};

export function logoWrapperClassName(isScrolledAwayOfHeaderHeight?: boolean) {
	return cn(
		'flex-grow basis-[90px] md:basis-1/5 max-w-[180px] flex-shrink md:flex-shrink-0 order-1',
		isScrolledAwayOfHeaderHeight && 'hidden md:block',
	);
}

export function HeaderLogo({ noWrap, showLogo, isScrolledAwayOfHeaderHeight }: Props) {
	const initialVisibility = useSignal(showLogo);

	useEffect(() => {
		globalThis.document.addEventListener('astro:after-swap', () => {
			const headerData = globalThis.document.getElementById(HEADER_DATA_ELEMENT_ID);
			if (headerData) {
				initialVisibility.value = headerData.getAttribute('data-show-logo') === 'true';
			}
		});
	}, []);

	const logo = (
		<RevealOnElementLeftViewport elementId={HP_LOGO_ELEMENT_ID} initialVisibility={initialVisibility.value}>
			<a href="/" className="inline-block leading-0" data-dot="lista_sbazar">
				<span className="hidden text-red">Sbazar.cz</span>
				<LogoSbazar className="h-6" />
			</a>
		</RevealOnElementLeftViewport>
	);

	if (noWrap) {
		return logo;
	}

	return <div class={logoWrapperClassName(isScrolledAwayOfHeaderHeight)}>{logo}</div>;
}
