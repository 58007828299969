import { MerkurWidget, type RibbonData } from '@merkur/integration-react';
import { Header, type Props } from './Header';
import { useSignal, useComputed } from '@preact/signals';
import { createPortal, useEffect } from 'preact/compat';
import { getRibbonData } from 'service/ribbon/resource';
import {
	DESKTOP_MIN_WIDTH_BREAKPOINT,
	MOBILE_HEADER_HEIGHT,
	DESKTOP_HP_LOGO_POSITION_FROM_TOP,
	DESKTOP_HEADER_HEIGHT,
	DESKTOP_MIN_WIDTH_TRANSPARENT_HEADER_BREAKPOINT,
	HEADER_DATA_ELEMENT_ID,
} from 'utils/constants';
import { cn } from 'utils/cn';
import { HeaderLogo } from './HeaderLogo';
import { HeaderSearchBar } from './HeaderSearchBar';
import { navigate } from 'astro:transitions/client';
import { sendCreateOfferAnalytics, sendMyOffersAnalytics } from 'lib/dot/seller/utils.ts';
import { loginOpen, user } from 'signals/login.ts';
import { useCallback } from 'preact/hooks';
import { type SznLoginWidget } from 'utils/login';

type RibbonProps = Props & {
	data?: RibbonData;
};

const CONTAINER_SELECTOR = '.ribbon-widget';
const CENTER_CONTAINER_ID = '#ribbon__center';
const SERVICE_CONTAINER_SELECTOR = 'div.ribbon__start';

export const Ribbon = ({ ...props }: RibbonProps) => {
	const widgetProperties = useSignal(props.data);
	const widgetPropertiesWithSelector = useComputed(() => {
		const data = widgetProperties.value;
		return data
			? {
					...data,
					containerSelector: CONTAINER_SELECTOR,
					assets: data.assets.filter((asset) => asset.type !== 'inlineStyle'),
				}
			: data;
	});

	const ribbonCenterContainerElm = useSignal<HTMLElement | null>(null);
	const ribbonStartContainerElm = useSignal<HTMLElement | null>(null);
	const ribbonStartReady = useSignal(false);
	const ribbonCenterReady = useSignal(false);
	const isHeaderLinkVisible = useSignal(false);
	const isScrolledAwayOfHeaderHeight = useSignal(false);
	const isHeaderVisible = useSignal(true);
	const lastScrollState = useSignal(0);

	const wrapperClassName = cn(
		'bg-neutral-white fixed top-0 w-full z-ribbon flex items-center print:fixed border-solid border-b border-b-transparent ',
		isHeaderLinkVisible.value && 'md:border-b-gray header-shadow',
		!isHeaderVisible.value && 'hidden md:block',
		isScrolledAwayOfHeaderHeight.value ? 'border-b-gray fixed md:fixed' : 'absolute md:fixed',
	);

	useCloseLoginMenuOnNavigation();

	useEffect(() => {
		if (!props.data) getRibbonData().then((data) => (widgetProperties.value = data));

		const eventOptions: AddEventListenerOptions = { passive: true };
		const onScroll = () => {
			const isDesktopViewport = globalThis.window.innerWidth >= DESKTOP_MIN_WIDTH_BREAKPOINT;
			isHeaderLinkVisible.value = globalThis.window.scrollY > 0;
			const headerHeight = isDesktopViewport ? DESKTOP_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT;
			const scrollingDirectionUp = globalThis.window.scrollY < lastScrollState.value;
			isHeaderVisible.value = scrollingDirectionUp || globalThis.window.scrollY < headerHeight;
			isScrolledAwayOfHeaderHeight.value = globalThis.window.scrollY > headerHeight;

			lastScrollState.value = globalThis.window.scrollY;

			if (isHeaderLinkVisible.value) {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.add('header-shadow');
			} else {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.remove('header-shadow');
			}

			if (
				globalThis.window.location.pathname === '/' &&
				DESKTOP_HP_LOGO_POSITION_FROM_TOP > globalThis.window.scrollY &&
				globalThis.window.innerWidth >= DESKTOP_MIN_WIDTH_TRANSPARENT_HEADER_BREAKPOINT
			) {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.add('header-hp-logo-hidden');
			} else {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.remove('header-hp-logo-hidden');
			}

			if (isHeaderVisible.value) {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.remove('header-mobile-hidden');
			} else {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.add('header-mobile-hidden');
			}

			if (isScrolledAwayOfHeaderHeight.value) {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.add('header-scrolled');
			} else {
				globalThis.document.querySelector(CONTAINER_SELECTOR)?.classList.remove('header-scrolled');
			}
		};

		const onClick = (event: Event) => {
			if (!window.login || window.login.current.state === 'login') {
				navigate('/nova-nabidka');
				sendCreateOfferAnalytics('ribbon');
				return;
			}

			event.preventDefault();
			loginOpen(
				{ sourceComponent: 'add_offer' },
				{
					callback: () => {
						sendCreateOfferAnalytics('ribbon');
						navigate('/nova-nabidka');
					},
				},
			);
		};

		globalThis.window.addEventListener('scroll', onScroll, eventOptions);
		globalThis.document.addEventListener('astro:after-swap', onScroll, eventOptions);
		globalThis.window.addEventListener('ribbon', onClick);
		onScroll();

		return () => {
			globalThis.window.removeEventListener('scroll', onScroll, eventOptions);
			globalThis.document.removeEventListener('astro:after-swap', onScroll, eventOptions);
			globalThis.window.removeEventListener('ribbon', onClick);
		};
	}, []);

	if (!widgetProperties.value) {
		return (
			<div class={wrapperClassName}>
				<Header {...props} isScrolledAwayOfHeaderHeight={isScrolledAwayOfHeaderHeight.value} />
				<div
					id={HEADER_DATA_ELEMENT_ID}
					data-vtbot-replace="headerData"
					data-show-logo={props.showLogo}
					data-show-search={props.showSearch}
				></div>
			</div>
		);
	}

	const ribbonControlsClick = useCallback((event: Event) => {
		// Use .getAttribute('href') instead of .href directly, because direct access is fully qualified URL
		if ((event.target as HTMLAnchorElement).getAttribute('href') === '/muj-bazar') {
			sendMyOffersAnalytics(user.peek().uid!);
		}
	}, []);

	return (
		<>
			<MerkurWidget
				widgetProperties={widgetPropertiesWithSelector.value}
				onWidgetUnmouting={() => {
					document.querySelector('ribbon-badge')?.removeEventListener('click', ribbonControlsClick);
				}}
				onWidgetMounted={() => {
					ribbonCenterContainerElm.value = globalThis.document.querySelector(CENTER_CONTAINER_ID);
					ribbonStartContainerElm.value = globalThis.document.querySelector(SERVICE_CONTAINER_SELECTOR);

					globalThis.document.querySelectorAll('style').forEach((styleElm) => {
						if (styleElm.innerHTML.includes('ribbon--base')) {
							styleElm.innerHTML = 'Resetting styles';
						}
					});

					globalThis.document.querySelector('#ribbon-os-menu')?.remove();
					globalThis.document.querySelector('image.ribbon-service__logo')?.remove();
					globalThis.document.querySelector('.ribbon-seznam__link')?.classList.add('link');
					globalThis.document.querySelector('.ribbon-control__label')?.classList.add('link');
					globalThis.document.querySelector('#ribbon-badge')?.addEventListener('click', ribbonControlsClick);

					if (ribbonStartContainerElm.value) {
						ribbonStartContainerElm.value.innerHTML = '';
						ribbonStartReady.value = true;
					}

					if (ribbonCenterContainerElm.value) {
						ribbonCenterReady.value = true;
					}
				}}
			></MerkurWidget>
			{ribbonStartContainerElm.value &&
				ribbonStartReady.value &&
				createPortal(
					<HeaderLogo
						noWrap
						showLogo={props.showLogo}
						isScrolledAwayOfHeaderHeight={isScrolledAwayOfHeaderHeight.value}
					/>,
					ribbonStartContainerElm.value,
				)}
			{ribbonCenterContainerElm.value &&
				ribbonCenterReady.value &&
				createPortal(
					<HeaderSearchBar
						noWrap
						showSearch={props.showSearch}
						showSearchOnScroll={props.showSearchOnScroll}
						focusedInputClassName={props.focusedInputClassName}
					/>,
					ribbonCenterContainerElm.value,
				)}
			<div
				id={HEADER_DATA_ELEMENT_ID}
				data-vtbot-replace="headerData"
				data-show-logo={props.showLogo}
				data-show-search={props.showSearch}
			></div>
		</>
	);
};

function useCloseLoginMenuOnNavigation() {
	useEffect(() => {
		const onSpaNavigation = () => {
			const widget = globalThis.document.querySelector<SznLoginWidget>('szn-login-widget');
			const closePopup =
				widget?.closePopup && typeof widget?.closePopup === 'function' && widget?.closePopup.bind(widget);
			if (closePopup) {
				closePopup();
			}
		};

		globalThis.document.addEventListener('astro:before-preparation', onSpaNavigation);
	}, []);
}
