import { type ComponentChildren } from 'preact';
import { useRevealOnElementLeftViewport } from 'components/header/useRevealOnElementLeftViewport';
import { cn } from 'utils/cn';

type Props = {
	className?: string;
	children: ComponentChildren;
	elementId?: string;
	hideHard?: boolean;
	hideClassName?: string;
	initialVisibility?: boolean;
};

export function RevealOnElementLeftViewport({
	className,
	children,
	elementId,
	hideClassName = 'hidden',
	initialVisibility = false,
}: Props) {
	const isVisible = useRevealOnElementLeftViewport(elementId, initialVisibility);

	return (
		<div className={cn('leading-0 transition-opacity duration-200 ', !isVisible.value && hideClassName, className)}>
			{children}
		</div>
	);
}
