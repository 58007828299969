import { AddOffer } from 'components/header/AddOffer';
import { baseUrl } from 'utils/env';
import { useEffect } from 'preact/hooks';
import { sendMyOffersAnalytics } from 'lib/dot/seller/utils.ts';
import { user } from 'signals/login.ts';

type Props = {
	showLogin?: boolean;
	isNewSbazar: boolean;
	className?: string;
};

export function Medailonek({ showLogin = true, className, isNewSbazar }: Props) {
	// use old url for my offers until redesign release. It could affect 404 page on IMA.js
	const myOffersUrl = isNewSbazar ? '/muj-bazar' : '/admin/moje-inzeraty/zverejnene';
	const newOffersUrl = isNewSbazar ? `${baseUrl()}/nova-nabidka` : `${baseUrl()}/admin/novy-inzerat`;
	const favoritesLabel = isNewSbazar ? 'Uložené nabídky' : 'Oblíbené nabídky';
	const favoritesUrl = isNewSbazar ? '/ulozene-nabidky' : '/admin/oblibene';
	const savedSearchUrl = isNewSbazar ? '/ulozena-hledani' : '/admin/ulozena-hledani';

	useEffect(() => {
		const eventOptions: AddEventListenerOptions = { passive: true };
		let isInside = false;
		const computeNotificationIsInside = () => {
			const matchMedia = globalThis.window.matchMedia('(max-width: 640px)');
			if (!isInside && matchMedia.matches) {
				globalThis.document.querySelector('szn-login-widget')?.setAttribute('notifications', 'inside');
			}

			if (isInside && !matchMedia.matches) {
				globalThis.document.querySelector('szn-login-widget')?.setAttribute('notifications', 'outside');
			}

			isInside = matchMedia.matches;
		};

		computeNotificationIsInside();
		globalThis.window.addEventListener('resize', computeNotificationIsInside, eventOptions);

		return () => {
			globalThis.window.removeEventListener('resize', computeNotificationIsInside, eventOptions);
		};
	}, []);

	if (!showLogin) {
		return (
			<div className={className}>
				<div class="hidden min-[500px]:flex flex-none items-center text-red text-sm">
					<a
						href="https://www.seznam.cz"
						rel="noopener noreferrer"
						target="_blank"
						data-dot="lista_odkaz_seznam"
						class="hover:text-red"
					>
						Seznam.cz
					</a>
				</div>
			</div>
		);
	}

	return (
		<div className={className}>
			<div class="ribbon-control flex flex-none items-center">
				<AddOffer url={newOffersUrl} />
			</div>
			<nav
				role="navigation"
				aria-labelledby="ribbon-os-menu"
				class="pl-3 min-[500px]:px-3 w-auto min-[640px]:min-w-48 flex-auto flex items-center justify-items-end flex-row-reverse gap-6"
			>
				<div class="hidden min-[500px]:flex flex-none items-center text-red text-sm">
					<a
						href="https://www.seznam.cz"
						rel="noopener noreferrer"
						target="_blank"
						data-dot="lista_odkaz_seznam"
						class="hover:text-red"
					>
						Seznam.cz
					</a>
				</div>
				<div
					id="ribbon-badge"
					aria-label="Osobní navigace, přihlášení a další služby"
					class="ribbon-badge flex items-center justify-start flex-row-reverse gap-6 max-[640px]:w-10 min-[640.01px]:min-w-48"
				>
					{/* @ts-expect-error = valid element */}
					<szn-login-widget notifications="outside" data-dot="login-badge">
						<ul>
							<li>
								<a href={favoritesUrl}>{favoritesLabel}</a>
							</li>
							<li>
								<a href={savedSearchUrl}>Uložená hledání</a>
							</li>
							<li>
								<a
									href={myOffersUrl}
									onClick={() => {
										sendMyOffersAnalytics(user.peek().uid!);
									}}
								>
									Můj bazar
								</a>
							</li>
						</ul>
						{/* @ts-expect-error = valid element */}
					</szn-login-widget>
				</div>
			</nav>
		</div>
	);
}
