import { Medailonek } from 'components/header/Medailonek';
import { cn } from 'utils/cn';
import { HeaderSearchBar } from './HeaderSearchBar';
import { HeaderLogo } from './HeaderLogo';

export type Props = {
	focusedInputClassName?: string;
	showSearch?: boolean;
	showSearchOnScroll: boolean;
	showLogin?: boolean;
	showLogo?: boolean;
	isNewSbazar: boolean;
	isScrolledAwayOfHeaderHeight?: boolean;
};

export function medailonekClassName(isScrolledAwayOfHeaderHeight: boolean) {
	return cn(
		'basis-auto flex-none flex order-3 md:order-4 h-14 md:h-auto items-center justify-end',
		isScrolledAwayOfHeaderHeight && 'hidden md:flex',
	);
}

export function headerClassName(showSearch?: boolean) {
	return cn(
		'flex flex-row justify-between items-center px-3 md:px-4 flex-wrap md:flex-nowrap grow min-h-desktopHeader md:min-h-desktopHeader',
		!showSearch && 'min-h-0',
	);
}

export function Header({
	showSearch = true,
	showSearchOnScroll,
	showLogin = true,
	showLogo = true,
	focusedInputClassName,
	isNewSbazar,
	isScrolledAwayOfHeaderHeight = false,
}: Props) {
	return (
		<header aria-label="Hlavička" amp-fx="float-in-top" role="banner" class={headerClassName(showSearch)}>
			<HeaderLogo showLogo={showLogo} isScrolledAwayOfHeaderHeight={isScrolledAwayOfHeaderHeight} />
			<HeaderSearchBar
				className="flex-initial md:w-[712px] mx-1 max-w-full basis-full md:basis-auto my-3 md:m-0 md:mx-5 order-4 md:order-3"
				showSearch={showSearch}
				showSearchOnScroll={showSearchOnScroll}
				focusedInputClassName={focusedInputClassName}
			/>
			<Medailonek
				showLogin={showLogin}
				isNewSbazar={isNewSbazar}
				className={medailonekClassName(isScrolledAwayOfHeaderHeight)}
			/>
		</header>
	);
}
