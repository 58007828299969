import { useSignal } from '@preact/signals';
import { useEffect } from 'preact/hooks';

export function useRevealOnElementLeftViewport(elementId?: string, initialVisibility = false) {
	const isVisible = useSignal(initialVisibility);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					isVisible.value = !entry.isIntersecting;
				});
			},
			{
				rootMargin: '-70px 0px',
			},
		);

		const observeElement = () => {
			const element = globalThis.document.querySelector(`main #${elementId}`);

			if (element) {
				isVisible.value = false;
				observer.observe(element);
			}
		};

		observeElement();

		globalThis.document.addEventListener('astro:after-swap', observeElement);
		return () => {
			observer.disconnect();
		};
	}, []);

	return isVisible;
}
