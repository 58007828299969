import { navigate } from 'astro:transitions/client';
import { localize } from 'i18n/localize';
import { useCallback } from 'preact/hooks';
import { loginOpen } from 'signals/login';
import { sendCreateOfferAnalytics } from 'lib/dot/seller/utils.ts';

type Props = {
	url: string;
};

const $t = localize();

export function AddOffer({ url }: Props) {
	const onClick = useCallback(
		(event: MouseEvent) => {
			if (!globalThis.window.login || globalThis.window.login.current.state === 'login') {
				sendCreateOfferAnalytics('other');
				return;
			}

			event.preventDefault();
			loginOpen(
				{ sourceComponent: 'add_offer' },
				{
					callback: () => {
						sendCreateOfferAnalytics('other');
						navigate(url);
					},
				},
			);
		},
		[url],
	);

	return (
		<a href={url} type="button" data-dot="lista_tlacitko" onClick={onClick}>
			<span class="text-red text-sm">{$t.bazar.addOffer}</span>
		</a>
	);
}
