import { type RibbonData } from '@merkur/integration-react';
import { isProduction, tld } from 'utils/env';
import { createAPIMethod } from 'utils/generic';

const RIBBON_TIMEOUT_MS = 500;
const RIBBON_CACHE_TTL_MS = 30000;
const RIBBON_BASE = isProduction() ? 'https://ribbon.seznam.cz/v2' : `https://ribbon.${tld()}`;

export async function getRibbonData(headers?: HeadersInit) {
	if (import.meta.env.SSR && globalThis.process.env.BUILD_TIME) return undefined;

	try {
		const data = await createAPIMethod<undefined, RibbonData>({
			method: 'GET',
			path: '',
			useCache: true,
			cacheTtl: RIBBON_CACHE_TTL_MS,
			url: `${RIBBON_BASE}/widget?serviceID=sbazar-v2`,
			requestTimeout: RIBBON_TIMEOUT_MS,
			headers,
		})();
		return data;
	} catch (error) {
		console.error('getRibbonData error', error);
		return undefined;
	}
}
